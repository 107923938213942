import { Box, Divider, Typography } from '@mui/material';

import { ComponentStyleProps } from '../pages/types';
import { hexToRgb } from './admin-utils';
import withCommonStyles from './WithCommoStyles';

interface IngressProps extends ComponentStyleProps {
  title: string;
  paragraph: string;
  sx: any;
}

const Ingress: React.FC<IngressProps> = ({ title, paragraph, sx }) => {
  console.log('Ingress component rendered', hexToRgb(sx.color));
  return (
    <>
      <Box
        sx={{
          margin: '20px',
          py: '10px',
          marginTop: '30px',
          maxWidth: '1100px',
          width: '100%',
        }}
        style={sx}
      >
        <Typography
          variant="h3"
          sx={{
            textAlign: 'center',
          }}
        >
          {title}
        </Typography>
        <Divider
          sx={{
            borderTop: '1px solid #000',
            width: '100%',
            margin: '10px 0',

            borderColor: sx?.color ? `rgba(${hexToRgb(sx.color)}, 0.2)` : '',
          }}
        />
        <Typography variant="h5">{paragraph}</Typography>
      </Box>
    </>
  );
};

export default withCommonStyles(Ingress);
