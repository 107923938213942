import React, { createContext, useContext, useEffect, useState } from 'react';

import { Button, useMediaQuery } from '@mui/material';
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';
import { deepmerge } from '@mui/utils';

import { Config } from './App';
import defaultTheme from './theme';

interface ConfigContextProps {
  config: Config | null;
  isLoading: boolean;
  handleUpload: (blob: Blob, filename: string) => void;
}

const ConfigContext = createContext<ConfigContextProps | undefined>(undefined);

const hostname = window.location.hostname;

export const useConfig = (): ConfigContextProps => {
  const context = useContext(ConfigContext);
  if (!context) {
    throw new Error('useConfig must be used within a ConfigProvider');
  }
  return context;
};

export const handleUpload = async (blob: Blob, filename: string) => {
  const hostname = window.location.hostname;
  console.log('hostname:', hostname);
  if (hostname === 'localhost') {
    console.log('we are in localhost:', filename);
    // Save the file locally using the File System Access API
    try {
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
      console.log('File saved locally');
    } catch (error) {
      console.error('Error saving file locally:', error);
    }
  } else {
    // Upload the file to the server
    const uploadUrl = `https://upload.anundi.fi/upload/${hostname}`;
    console.log('Uploading to:', uploadUrl);

    // Create a FormData instance
    const formData = new FormData();
    formData.append('file', blob, `${filename}`);

    try {
      const response = await fetch(uploadUrl, {
        method: 'PUT',
        body: formData,
        mode: 'cors', // Explicitly request CORS
        credentials: 'include',
        headers: {
          // Sometimes explicitly setting Content-Length helps
          // Let the browser handle content-type for FormData
        },
      });

      if (!response.ok) {
        // Check specific error status codes
        if (response.status === 403) {
          alert('Access denied. Your IP might not be allowed.');
        } else {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
      }

      console.log('File uploaded successfully to:', uploadUrl);
    } catch (error) {
      console.error('Error uploading file:', error);
      // Show user-friendly error message
      alert(error);
    }
  }
};

export const ConfigProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [config, setConfig] = useState<Config | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [configName, setConfigName] = useState('');
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  useEffect(() => {
    const fetchConfig = async () => {
      setIsLoading(true);
      const hostname = window.location.hostname;
      let configUrl = `./site_data/${hostname}/${hostname}.json`;

      setConfigName(hostname);
      if (hostname === 'localhost') {
        configUrl = `./site_data/${hostname}/${hostname}.json`;
      } else {
        configUrl = `/site_data/${hostname}/${hostname}.json`;
      }
      console.log('Fetching config from:', configUrl);

      try {
        let data;
        if (process.env.NODE_ENV === 'development') {
          // Read the config file directly from the local file system
          const response = await fetch(configUrl);
          if (!response.ok) {
            data = null;
          } else {
            data = await response.json();
          }
        } else {
          // Fetch the config file from the server
          const response = await fetch(configUrl);
          if (!response.ok) {
            data = null;
          } else {
            data = await response.json();
          }
        }

        // Validate the configuration
        if (!data || !data.pages || !data.pages.Home || !data.footerConfig) {
          console.error('Invalid configuration:', data);
          data = null;
        }

        if (data && !data.theme) {
          data.theme = defaultTheme;
        }

        setConfig(data);
      } catch (error) {
        console.error('Error fetching config:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchConfig();
  }, []);

  const handleNewConfig = async () => {
    const newConfig: Config = {
      theme: defaultTheme,
      pages: {
        Home: {
          menutitle: 'Home',
          components: [],
        },
      },
      navigationConfig: {},
      footerConfig: {},
    };

    const blob = new Blob([JSON.stringify(newConfig, null, 2)], {
      type: 'application/json',
    });
    await handleUpload(blob, `${configName}.json`);
    console.log('New config created successfully');
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!config || !config.pages || !config.pages.Home || !config.footerConfig) {
    return (
      <div>
        <Button variant="contained" color="primary" onClick={handleNewConfig}>
          Create New Config
        </Button>
      </div>
    );
  }
  console.log(prefersDarkMode);
  // Correct approach - set mode during theme creation
  const theme = createTheme(
    deepmerge(defaultTheme, {
      // First apply all other theme settings
      ...config.theme,
      palette: {
        // Then apply palette settings from config
        ...config.theme?.palette,
        // Finally override mode with calculated value
        mode:
          config.theme?.palette?.mode || (prefersDarkMode ? 'dark' : 'light'),
      },
    })
  );
  console.log('theme:', theme);
  return (
    <ConfigContext.Provider value={{ config, isLoading, handleUpload }}>
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
    </ConfigContext.Provider>
  );
};
