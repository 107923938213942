import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ReactRouterPrompt from 'react-router-prompt';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';

import { Config, FooterConfig, NavigationConfig, PageData } from '../App';
import { ThemeEditor } from '../components/admin/theme-editor';
import FileSelectorModal from '../components/FileSelectorModal';
import PageEditor from '../components/PageEditor';
import { handleUpload, useConfig } from '../ConfigContext';

const AdminPage = () => {
  const config = useConfig().config;
  const [configParam, setConfigParam] = useState<string | null>('anundi');

  if (!config) {
    return null;
  }
  const [currentConfig, setCurrentConfig] = useState<Config>(config);

  const [activePage, setActivePage] = useState<string>(
    Object.keys(currentConfig.pages)[0] || '' // Set initial active page safely
  );
  const [newPageName, setNewPageName] = useState('');
  const [isAddPageDialogOpen, setIsAddPageDialogOpen] = useState(false);
  const [isNavDialogOpen, setIsNavDialogOpen] = useState(false);
  const [isFooterDialogOpen, setIsFooterDialogOpen] = useState(false);

  const [isThemeDialogOpen, setIsThemeDialogOpen] = useState(false);
  const [isLogoFileSelectorOpen, setIsLogoFileSelectorOpen] = useState(false);

  const [isDirty, setIsDirty] = useState(false);

  const [isRenamePageDialogOpen, setIsRenamePageDialogOpen] = useState(false);
  const [newPageKey, setNewPageKey] = useState('');

  useEffect(() => {
    setIsDirty(true);
  }, [currentConfig]);

  const handlePageChange = (event: React.SyntheticEvent, newValue: string) => {
    setActivePage(newValue);
  };

  const handleSave = (updatedPageData: PageData) => {
    setCurrentConfig((prevConfig) => ({
      ...prevConfig,
      pages: {
        ...prevConfig.pages,
        [activePage]: updatedPageData,
      },
    }));
  };

  const handleConfigSave = () => {
    console.log('Saving config:', currentConfig);
    const blob = new Blob([JSON.stringify(currentConfig, null, 2)], {
      type: 'application/json',
    });
    const filename = `${configParam}.json`;
    handleUpload(blob, filename);
  };

  useEffect(() => {
    const hostname = window.location.hostname;
    let param = hostname ? hostname : null;
    console.log('using config name:', param);

    if (!param) {
      const urlParams = new URLSearchParams(window.location.search);
      param = urlParams.get('config');
    }

    if (!param) {
      param = 'anundi';
    }

    setConfigParam(param);
  }, []);

  const handleAddPage = () => {
    setCurrentConfig((prevConfig) => ({
      ...prevConfig,
      pages: {
        ...prevConfig.pages,
        [newPageName]: { menutitle: newPageName, components: [] },
      },
    }));
    setNewPageName('');
    setIsAddPageDialogOpen(false);
  };

  const handleDeletePage = () => {
    setCurrentConfig((prevConfig) => {
      const updatedPages = { ...prevConfig.pages };
      delete updatedPages[activePage];

      // Set active page to the first page in the updated list
      const newActivePage = Object.keys(updatedPages)[0] || '';

      return {
        ...prevConfig,
        pages: updatedPages,
        activePage: newActivePage,
      };
    });
  };

  const handleLogoFileSelect = (fileUrl: string) => {
    handleNavChange({
      ...(currentConfig.navigationConfig || {}),
      logo: {
        url: fileUrl,
        alt: currentConfig.navigationConfig?.logo?.alt || 'Logo',
        height: currentConfig.navigationConfig?.logo?.height || '40px',
      },
    });
    setIsLogoFileSelectorOpen(false);
  };

  const handleNavChange = (updatedNavConfig: NavigationConfig) => {
    setCurrentConfig((prevConfig) => ({
      ...prevConfig,
      navigationConfig: {
        ...prevConfig.navigationConfig,
        ...updatedNavConfig,
      },
    }));
  };

  const handleSaveFooter = (updatedFooterConfig: FooterConfig) => {
    setCurrentConfig((prevConfig) => ({
      ...prevConfig,
      footerConfig: updatedFooterConfig,
    }));
  };

  const handleThemeChange = (key: string, value: any) => {
    setCurrentConfig((prevConfig) => {
      const keys = key.split('.');
      const lastKey = keys.pop();
      const newTheme = { ...prevConfig.theme };

      let nestedConfig = newTheme;
      keys.forEach((k) => {
        nestedConfig[k] = { ...nestedConfig[k] };
        nestedConfig = nestedConfig[k];
      });

      if (lastKey) {
        nestedConfig[lastKey] = value;
      }

      return {
        ...prevConfig,
        theme: newTheme,
      };
    });
  };

  const handleRenamePage = () => {
    if (newPageKey && newPageKey !== activePage) {
      setCurrentConfig((prevConfig) => {
        const updatedPages = { ...prevConfig.pages };
        // Copy the content from the old key to the new key
        updatedPages[newPageKey] = updatedPages[activePage];
        // Delete the old key
        delete updatedPages[activePage];

        return {
          ...prevConfig,
          pages: updatedPages,
        };
      });

      // Update active page to the new name
      setActivePage(newPageKey);
      setIsRenamePageDialogOpen(false);
    }
  };

  const handleMoveTab = (pageKey: string, direction: 'up' | 'down') => {
    setCurrentConfig((prevConfig) => {
      // Get ordered list of page keys
      const pageKeys = Object.keys(prevConfig.pages);
      const currentIndex = pageKeys.indexOf(pageKey);

      // Calculate new index
      const newIndex =
        direction === 'up'
          ? Math.max(0, currentIndex - 1)
          : Math.min(pageKeys.length - 1, currentIndex + 1);

      // If position doesn't change, return unchanged config
      if (newIndex === currentIndex) return prevConfig;

      // Create new ordered array of keys by swapping positions
      const newPageKeys = [...pageKeys];
      [newPageKeys[currentIndex], newPageKeys[newIndex]] = [
        newPageKeys[newIndex],
        newPageKeys[currentIndex],
      ];

      // Create new pages object with the updated order
      const orderedPages: typeof prevConfig.pages = {};
      newPageKeys.forEach((key) => {
        orderedPages[key] = prevConfig.pages[key];
      });

      // Return updated config
      return {
        ...prevConfig,
        pages: orderedPages,
      };
    });
  };

  if (!configParam) {
    return <div>Config not found. You need to create subdomain.</div>;
  }

  return (
    <Box sx={{ paddingTop: '100px' }}>
      <Button variant="contained" color="primary" component={Link} to="/">
        Takaisin sivulle
      </Button>
      <Box sx={{ height: '300%' }}></Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box sx={{ width: '70%' }}>
          <Tabs
            value={activePage}
            onChange={handlePageChange}
            aria-label="page navigation tabs"
            variant="scrollable"
            scrollButtons="auto"
            textColor="secondary"
            indicatorColor="secondary"
          >
            {Object.keys(currentConfig.pages).map((pageKey) => (
              <Tab
                key={pageKey}
                value={pageKey}
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box
                      sx={{ display: 'flex', flexDirection: 'column', mr: 1 }}
                    >
                      <Button
                        size="small"
                        color="secondary"
                        sx={{ minWidth: 'auto', p: 0, minHeight: '12px' }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleMoveTab(pageKey, 'up');
                        }}
                      >
                        ◀
                      </Button>
                    </Box>
                    {pageKey}
                    {activePage === pageKey && (
                      <Button
                        size="small"
                        color="secondary"
                        sx={{ ml: 1, minWidth: 'auto', p: '2px' }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setNewPageKey(pageKey);
                          setIsRenamePageDialogOpen(true);
                        }}
                      >
                        ✏️
                      </Button>
                    )}
                    <Button
                      size="small"
                      color="secondary"
                      sx={{
                        minWidth: 'auto',
                        p: 0,
                        minHeight: '12px',
                        paddingLeft: '5px',
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleMoveTab(pageKey, 'down');
                      }}
                    >
                      ▶
                    </Button>
                  </Box>
                }
                wrapped
                sx={{
                  color: 'black',
                  fontWeight: activePage === pageKey ? 'bold' : 'normal',
                }}
              />
            ))}
          </Tabs>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 1,
            justifyContent: 'flex-start',
            width: '100%',
          }}
        >
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setIsAddPageDialogOpen(true)}
            sx={{ flex: '0 1 auto' }}
          >
            Add Page
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleDeletePage}
            disabled={Object.keys(currentConfig.pages).length <= 1}
            sx={{ flex: '0 1 auto' }}
          >
            Delete Page
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => setIsThemeDialogOpen(true)}
            sx={{ flex: '0 1 auto' }}
          >
            Edit Theme
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => setIsNavDialogOpen(true)}
            sx={{ flex: '0 1 auto' }}
          >
            Edit Navigation
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => setIsFooterDialogOpen(true)}
            sx={{ flex: '0 1 auto' }}
          >
            Edit Footer
          </Button>
        </Box>
      </Box>
      <PageEditor
        pageData={
          currentConfig.pages[activePage] || { menutitle: '', components: [] }
        }
        onSave={handleSave}
        handleUpload={handleUpload}
        configParam={configParam}
      />
      {/*save button */}
      <Button
        sx={{ position: 'fixed', bottom: '20px', right: '20px' }}
        variant="contained"
        color="primary"
        onClick={handleConfigSave}
      >
        Save
      </Button>
      {/* Add page Edit Dialog */}
      <Dialog
        open={isAddPageDialogOpen}
        onClose={() => setIsAddPageDialogOpen(false)}
      >
        <DialogTitle>Add New Page</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Page Name"
            type="text"
            fullWidth
            value={newPageName}
            onChange={(e) => setNewPageName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsAddPageDialogOpen(false)}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={handleAddPage}>
            Create
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isNavDialogOpen}
        onClose={() => setIsNavDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Edit Navigation</DialogTitle>
        <DialogContent>
          {/* Logo upload section */}
          <Box
            sx={{ mb: 3, border: '1px solid #e0e0e0', p: 2, borderRadius: 1 }}
          >
            <Typography variant="subtitle1" fontWeight="bold" mb={1}>
              Logo Settings
            </Typography>

            {/* Show logo preview if exists */}
            {currentConfig.navigationConfig?.logo?.url && (
              <Box sx={{ mb: 2, display: 'flex', justifyContent: 'center' }}>
                <img
                  src={currentConfig.navigationConfig.logo.url}
                  alt="Logo preview"
                  style={{ maxHeight: '80px' }}
                />
              </Box>
            )}

            <Button
              variant="contained"
              color="primary"
              onClick={() => setIsLogoFileSelectorOpen(true)}
              sx={{ mb: 2 }}
            >
              Select Logo
            </Button>

            <TextField
              margin="dense"
              label="Logo Alt Text"
              type="text"
              fullWidth
              value={currentConfig.navigationConfig?.logo?.alt || ''}
              onChange={(e) =>
                handleNavChange({
                  ...(currentConfig.navigationConfig || {}),
                  logo: {
                    ...(currentConfig.navigationConfig?.logo || { url: '' }),
                    alt: e.target.value,
                  },
                })
              }
            />

            <TextField
              margin="dense"
              label="Logo Height"
              type="text"
              fullWidth
              value={currentConfig.navigationConfig?.logo?.height || '40px'}
              onChange={(e) =>
                handleNavChange({
                  ...(currentConfig.navigationConfig || {}),
                  logo: {
                    ...(currentConfig.navigationConfig?.logo || {
                      url: '',
                      alt: 'Logo',
                    }),
                    height: e.target.value,
                  },
                })
              }
              helperText="Example: 40px or 3rem"
            />
          </Box>

          {/* Navigation behavior settings */}
          <Box sx={{ border: '1px solid #e0e0e0', p: 2, borderRadius: 1 }}>
            <Typography variant="subtitle1" fontWeight="bold" mb={1}>
              Navigation Behavior
            </Typography>

            <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
              <Typography>Show Home Text: </Typography>
              <Switch
                checked={currentConfig.navigationConfig?.showHomeText || false}
                onChange={(e) =>
                  handleNavChange({
                    ...(currentConfig.navigationConfig || {}),
                    showHomeText: e.target.checked,
                  })
                }
              />
              <Typography sx={{ ml: 2 }}>
                {currentConfig.navigationConfig?.showHomeText
                  ? "Showing 'Home' text"
                  : 'Using logo only'}
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
              <Typography>Last Item as Button: </Typography>
              <Switch
                checked={
                  currentConfig.navigationConfig?.lastItemIsButton || false
                }
                onChange={(e) =>
                  handleNavChange({
                    ...(currentConfig.navigationConfig || {}),
                    lastItemIsButton: e.target.checked,
                  })
                }
              />
              <Typography sx={{ ml: 2 }}>
                {currentConfig.navigationConfig?.lastItemIsButton
                  ? 'Last item styled as button'
                  : 'All items as text links'}
              </Typography>
            </Box>

            {currentConfig.navigationConfig?.lastItemIsButton && (
              <TextField
                margin="dense"
                label="Button Color"
                type="text"
                fullWidth
                value={currentConfig.navigationConfig?.buttonColor || 'primary'}
                onChange={(e) =>
                  handleNavChange({
                    ...(currentConfig.navigationConfig || {}),
                    buttonColor: e.target.value,
                  })
                }
                helperText="Use 'primary', 'secondary', or a hex color like '#FF5733'"
              />
            )}
          </Box>
          <FileSelectorModal
            open={isLogoFileSelectorOpen}
            onClose={() => setIsLogoFileSelectorOpen(false)}
            onSelect={handleLogoFileSelect}
            configParam={configParam}
            handleUpload={handleUpload}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsNavDialogOpen(false)}>Cancel</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setIsNavDialogOpen(false)}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Footer Edit Dialog */}
      <Dialog open={isFooterDialogOpen}>
        <DialogTitle>Edit Footer</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Title"
            type="text"
            fullWidth
            value={currentConfig.footerConfig?.title || ''}
            onChange={(e) =>
              handleSaveFooter({
                ...currentConfig.footerConfig,
                title: e.target.value,
              })
            }
          />
          <TextField
            autoFocus
            margin="dense"
            label="Subtitle"
            type="text"
            fullWidth
            value={currentConfig.footerConfig?.subtitle || ''}
            onChange={(e) =>
              handleSaveFooter({
                ...currentConfig.footerConfig,
                subtitle: e.target.value,
              })
            }
          />
          <TextField
            autoFocus
            margin="dense"
            label="Instagram"
            type="text"
            fullWidth
            value={currentConfig.footerConfig?.links?.instagram || ''}
            onChange={(e) =>
              handleSaveFooter({
                ...currentConfig.footerConfig,
                links: {
                  ...currentConfig.footerConfig.links,
                  instagram: e.target.value,
                },
              })
            }
          />

          <TextField
            autoFocus
            margin="dense"
            label="Facebook"
            type="text"
            fullWidth
            value={currentConfig.footerConfig?.links?.facebook || ''}
            onChange={(e) =>
              handleSaveFooter({
                ...currentConfig.footerConfig,
                links: {
                  ...currentConfig.footerConfig.links,
                  facebook: e.target.value,
                },
              })
            }
          />

          <TextField
            autoFocus
            margin="dense"
            label="Youtube"
            type="text"
            fullWidth
            value={currentConfig.footerConfig?.links?.youtube || ''}
            onChange={(e) =>
              handleSaveFooter({
                ...currentConfig.footerConfig,
                links: {
                  ...currentConfig.footerConfig.links,
                  youtube: e.target.value,
                },
              })
            }
          />

          <TextField
            autoFocus
            margin="dense"
            label="LinkedIn"
            type="text"
            fullWidth
            value={currentConfig.footerConfig?.links?.linkedin || ''}
            onChange={(e) =>
              handleSaveFooter({
                ...currentConfig.footerConfig,
                links: {
                  ...currentConfig.footerConfig.links,
                  linkedin: e.target.value,
                },
              })
            }
          />

          <TextField
            autoFocus
            margin="dense"
            label="X"
            type="text"
            fullWidth
            value={currentConfig.footerConfig?.links?.x || ''}
            onChange={(e) =>
              handleSaveFooter({
                ...currentConfig.footerConfig,
                links: {
                  ...currentConfig.footerConfig.links,
                  x: e.target.value,
                },
              })
            }
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setIsFooterDialogOpen(false)}>Cancel</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setIsFooterDialogOpen(false)}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Theme Edit Dialog */}
      <Dialog
        open={isThemeDialogOpen}
        onClose={() => setIsThemeDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <ThemeEditor
          currentConfig={currentConfig}
          handleThemeChange={handleThemeChange}
          onClose={() => setIsThemeDialogOpen(false)}
          configParam={configParam}
          handleUpload={handleUpload}
        />
      </Dialog>

      {/* Rename Page Dialog */}
      <Dialog
        open={isRenamePageDialogOpen}
        onClose={() => setIsRenamePageDialogOpen(false)}
      >
        <DialogTitle>Rename Page</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="New Page Name"
            type="text"
            fullWidth
            value={newPageKey}
            onChange={(e) => setNewPageKey(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsRenamePageDialogOpen(false)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleRenamePage}
            disabled={!newPageKey || newPageKey === activePage}
          >
            Rename
          </Button>
        </DialogActions>
      </Dialog>

      <ReactRouterPrompt when={isDirty}>
        {({ isActive, onConfirm, onCancel }) => (
          <Dialog open={isActive}>
            <div>
              <p>Do you really want to leave?</p>
              <button onClick={onCancel}>Cancel</button>
              <button onClick={onConfirm}>Ok</button>
            </div>
          </Dialog>
        )}
      </ReactRouterPrompt>
    </Box>
  );
};

export default AdminPage;
