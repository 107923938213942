import React from 'react';
import { createHashRouter, Navigate, RouterProvider } from 'react-router-dom';

import { Box } from '@mui/material';

import Footer from './components/footer';
import TopNavigation from './components/top-navigation';
import { useConfig } from './ConfigContext';
import AdminPage from './pages/admin';
import DynamicPage from './pages/dynamic-page';

export interface ComponentProps {
  [key: string]: any;
}

export interface PageConfig {
  menutitle: string;
  components: {
    type: string;
    props: ComponentProps;
  }[];
}
export interface PageData {
  menutitle: string;
  components: {
    type: string;
    props: ComponentProps;
  }[];
}
export interface NavigationConfig {
  logo?: {
    url: string;
    alt: string;
    height?: string;
  };
  homeLink?: string;
  showHomeText?: boolean;
  lastItemIsButton?: boolean;
  buttonColor?: string;
}

export interface FooterConfig {
  title?: string;
  subtitle?: string;
  links?: {
    instagram?: string;
    facebook?: string;
    x?: string;
    youtube?: string;
    linkedin?: string;
  };
}

export interface Config {
  theme: any;
  pages: Record<string, PageConfig>;
  navigationConfig: NavigationConfig;
  footerConfig: FooterConfig;
}

function isPageConfig(obj: any): obj is PageConfig {
  return (
    obj &&
    typeof obj === 'object' &&
    'menutitle' in obj &&
    'components' in obj &&
    Array.isArray(obj.components)
  );
}

const App: React.FC = () => {
  const { config } = useConfig();
  console.warn('Porfolio Generator version 1.0');
  const validPages: Record<string, PageConfig> = config?.pages
    ? Object.entries(config.pages).reduce((acc, [pageName, pageConfig]) => {
        if (isPageConfig(pageConfig)) {
          acc[pageName] = pageConfig;
        } else {
          console.error(`Invalid page configuration for: ${pageName}`);
        }
        return acc;
      }, {} as Record<string, PageConfig>)
    : {};

  const router = createHashRouter([
    {
      path: '/admin',
      element: <AdminPage />,
    },
    {
      path: '/Home',
      element: <Navigate to="/" replace />,
    },
    {
      path: '/',
      element: (
        <Box
          sx={{
            background: config?.theme.palette.background.default,
            backgroundImage: config?.theme.backgroundImage
              ? `url(${config.theme.backgroundImage})`
              : 'none',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundAttachment: 'fixed',
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
          }}
        >
          <TopNavigation />
          <Box sx={{ flexGrow: 1 }}>
            <DynamicPage pageName={'Home'} />
          </Box>
          <Footer />
        </Box>
      ),
    },
    ...Object.entries(validPages).map(([pageName]) => ({
      path: pageName === 'Home' ? '/' : `/${pageName}`,
      element: (
        <Box
          sx={{
            background: config?.theme.palette.background.default,
            backgroundImage: config?.theme.backgroundImage
              ? `url(${config.theme.backgroundImage})`
              : 'none',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundAttachment: 'fixed',
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
          }}
        >
          <TopNavigation />
          <Box sx={{ flexGrow: 1 }}>
            <DynamicPage pageName={pageName} />
          </Box>
          <Footer />
        </Box>
      ),
    })),
  ]);

  return <RouterProvider router={router} />;
};

export default App;
