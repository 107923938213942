import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { Typography } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';

import { useConfig } from '../ConfigContext';

const TopNavigation = () => {
  const theme = useTheme();
  const config = useConfig();
  const navConfig = config?.config?.navigationConfig || {};

  const pages = config?.config?.pages;
  const location = useLocation();

  if (!pages) {
    return null;
  }

  const [mobileOpen, setMobileOpen] = useState(false);
  const [hideNav, setHideNav] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY);
  const [selectedPage, setSelectedPage] = useState(location.pathname);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleScroll = () => {
    const currentScrollPos = window.scrollY;
    setHideNav(prevScrollPos < currentScrollPos && currentScrollPos > 50);
    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos]);

  useEffect(() => {
    setSelectedPage(location.pathname);
    window.scrollTo(0, 0); // Scroll to top when the selected page changes
  }, [location.pathname]);

  const pageKeys = Object.keys(pages);

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{ textAlign: 'center', zIndex: 1000, width: '99vw', paddingTop: 40 }}
    >
      {/* Close button in the top right corner */}
      <IconButton
        onClick={handleDrawerToggle}
        sx={{
          position: 'absolute',
          top: 10,
          right: 10,
          color: theme.palette.primary.main,
        }}
        aria-label="close menu"
      >
        <CloseIcon />
      </IconButton>
      <List
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        {pageKeys.map((pageKey) => (
          <ListItem
            key={pageKey}
            component={Link}
            to={`/${pageKey}`}
            sx={{
              justifyContent: 'center',
              width: 'auto',
              padding: '10px 0',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <ListItemText
              primary={
                <Typography
                  variant="body1"
                  sx={{
                    color: theme.palette.primary.main,
                    fontWeight:
                      selectedPage === `/${pageKey}` ? 'bold' : 'normal',
                    textDecoration:
                      selectedPage === `/${pageKey}` ? 'underline' : 'none',
                  }}
                >
                  {pages[pageKey].menutitle}
                </Typography>
              }
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Box
      sx={{
        flexGrow: 1,
        width: '100%',
        zIndex: 1000,
        position: 'fixed',
        pointerEvents: 'none',
        transition: 'top 0.3s',
        top: hideNav ? '-220px' : '0',
        justifyContent: 'center',
        textAlign: 'center',
      }}
    >
      <AppBar
        position="sticky"
        className="app-bar"
        sx={{
          boxShadow: 'none',
          height: { xs: 70, sm: 80, md: 100 },
        }}
      >
        <Toolbar
          sx={{
            pointerEvents: 'auto',
            display: 'flex',
            alignItems: 'center',
            height: '100%',
          }}
        >
          {/* Logo or Home Link */}
          {navConfig.logo?.url ? (
            <Box
              sx={{ flexGrow: 0, mr: 2, display: 'flex', alignItems: 'center' }}
            >
              <Link to={navConfig.homeLink || '/'}>
                <img
                  src={navConfig.logo.url}
                  alt={navConfig.logo.alt || 'Logo'}
                  style={{ height: navConfig.logo.height || '40px' }}
                />
              </Link>
            </Box>
          ) : (
            <Button
              variant="text"
              component={Link}
              to="/"
              sx={{
                fontWeight: selectedPage === '/' ? 'bold' : 'normal',
                textDecoration: selectedPage === '/' ? 'underline' : 'none',
              }}
            >
              {pages.Home?.menutitle || 'Home'}
            </Button>
          )}

          {/* Mobile Menu Button */}
          <IconButton
            color="primary"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ ml: 'auto', display: { md: 'none' } }} // Show only on small screens
          >
            <MenuIcon />
          </IconButton>

          {/* Desktop Navigation */}
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'none', md: 'flex' },
              justifyContent: 'flex-end',
              alignItems: 'center',
              height: '100%',
            }}
          >
            {pageKeys.map((pageKey, index, array) => {
              // Check if this is the last item and should be a button
              const isLast = index === array.length - 1;
              const showAsButton = isLast && navConfig.lastItemIsButton;

              return showAsButton ? (
                <Button
                  key={pageKey}
                  component={Link}
                  to={`/${pageKey}`}
                  variant="contained"
                  color={
                    navConfig.buttonColor === 'secondary'
                      ? 'secondary'
                      : 'primary'
                  }
                  sx={{
                    ml: 2,
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor:
                      navConfig.buttonColor &&
                      navConfig.buttonColor.startsWith('#')
                        ? navConfig.buttonColor
                        : undefined,
                  }}
                >
                  {pages[pageKey].menutitle}
                </Button>
              ) : (
                <Button
                  key={pageKey}
                  component={Link}
                  to={`/${pageKey}`}
                  color="inherit"
                  sx={{
                    ml: 2,
                    paddingLeft: 2,
                    paddingRight: 2,
                    borderRadius: 5,
                    fontWeight:
                      selectedPage === `/${pageKey}` ? 'bold' : 'normal',
                  }}
                >
                  {pages[pageKey].menutitle}
                </Button>
              );
            })}
          </Box>
        </Toolbar>
      </AppBar>

      {/* Mobile Drawer */}
      <Drawer anchor="right" open={mobileOpen} onClose={handleDrawerToggle}>
        {drawer}
      </Drawer>
    </Box>
  );
};

export default TopNavigation;
