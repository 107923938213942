/**
 * Validates a height value and ensures it has a valid CSS unit or is a valid number
 */
export const validateHeight = (value: string): string => {
  if (!value) return '';

  // If it's already a number with a unit, return it
  if (/^\d+(\.\d+)?(px|em|rem|vh|vw|%|vmin|vmax|cm|mm|in|pt|pc)$/.test(value)) {
    return value;
  }

  // If it's just a number, add 'px'
  if (/^\d+(\.\d+)?$/.test(value)) {
    return `${value}px`;
  }

  // If it's not a valid format, return empty string or default value
  return '';
};

/**
 * Validates a width value and ensures it has a valid CSS unit
 */
export const validateWidth = (value: string): string => {
  if (!value) return '';

  // If it's already a number with a unit, return it
  if (/^\d+(\.\d+)?(px|em|rem|vh|vw|%|vmin|vmax|cm|mm|in|pt|pc)$/.test(value)) {
    return value;
  }

  // If it's just a number, add 'px'
  if (/^\d+(\.\d+)?$/.test(value)) {
    return `${value}px`;
  }

  // If it's 'auto' or 'fit-content', accept these values
  if (['auto', 'fit-content', 'max-content', 'min-content'].includes(value)) {
    return value;
  }

  // If it's not a valid format, return empty string
  return '';
};

/**
 * Validates a CSS color value
 */
export const validateCSSColor = (value: string): string => {
  if (!value) return '';

  // Named colors, hex, rgb, rgba, hsl, hsla
  const colorRegex =
    /^(#([0-9a-f]{3}|[0-9a-f]{6}|[0-9a-f]{8})|(rgb|rgba|hsl|hsla)\(.*\))$/i;

  // If it's a hex color or rgb/rgba format
  if (colorRegex.test(value)) {
    return value;
  }

  // List of valid CSS color names
  const cssColorNames = [
    'aliceblue',
    'antiquewhite',
    'aqua',
    'aquamarine',
    'azure',
    'beige',
    'bisque',
    'black',
    'blanchedalmond',
    'blue',
    'blueviolet',
    'brown',
    'burlywood',
    'cadetblue',
    'chartreuse',
    'chocolate',
    'coral',
    'cornflowerblue',
    'cornsilk',
    'crimson',
    'cyan',
    'darkblue',
    'darkcyan',
    'darkgoldenrod',
    'darkgray',
    'darkgrey',
    'darkgreen',
    'darkkhaki',
    'darkmagenta',
    'darkolivegreen',
    'darkorange',
    'darkorchid',
    'darkred',
    'darksalmon',
    'darkseagreen',
    'darkslateblue',
    'darkslategray',
    'darkslategrey',
    'darkturquoise',
    'darkviolet',
    'deeppink',
    'deepskyblue',
    'dimgray',
    'dimgrey',
    'dodgerblue',
    'firebrick',
    'floralwhite',
    'forestgreen',
    'fuchsia',
    'gainsboro',
    'ghostwhite',
    'gold',
    'goldenrod',
    'gray',
    'grey',
    'green',
    'greenyellow',
    'honeydew',
    'hotpink',
    'indianred',
    'indigo',
    'ivory',
    'khaki',
    'lavender',
    'lavenderblush',
    'lawngreen',
    'lemonchiffon',
    'lightblue',
    'lightcoral',
    'lightcyan',
    'lightgoldenrodyellow',
    'lightgray',
    'lightgrey',
    'lightgreen',
    'lightpink',
    'lightsalmon',
    'lightseagreen',
    'lightskyblue',
    'lightslategray',
    'lightslategrey',
    'lightsteelblue',
    'lightyellow',
    'lime',
    'limegreen',
    'linen',
    'magenta',
    'maroon',
    'mediumaquamarine',
    'mediumblue',
    'mediumorchid',
    'mediumpurple',
    'mediumseagreen',
    'mediumslateblue',
    'mediumspringgreen',
    'mediumturquoise',
    'mediumvioletred',
    'midnightblue',
    'mintcream',
    'mistyrose',
    'moccasin',
    'navajowhite',
    'navy',
    'oldlace',
    'olive',
    'olivedrab',
    'orange',
    'orangered',
    'orchid',
    'palegoldenrod',
    'palegreen',
    'paleturquoise',
    'palevioletred',
    'papayawhip',
    'peachpuff',
    'peru',
    'pink',
    'plum',
    'powderblue',
    'purple',
    'rebeccapurple',
    'red',
    'rosybrown',
    'royalblue',
    'saddlebrown',
    'salmon',
    'sandybrown',
    'seagreen',
    'seashell',
    'sienna',
    'silver',
    'skyblue',
    'slateblue',
    'slategray',
    'slategrey',
    'snow',
    'springgreen',
    'steelblue',
    'tan',
    'teal',
    'thistle',
    'tomato',
    'turquoise',
    'violet',
    'wheat',
    'white',
    'whitesmoke',
    'yellow',
    'yellowgreen',
    'transparent',
  ];

  // Check if it's a valid CSS color name
  if (cssColorNames.includes(value.toLowerCase())) {
    return value;
  }

  // If it's not a valid color format, return empty string
  return '';
};

/**
 * Validates spacing values (margin, padding)
 */
export const validateSpacing = (value: string | number): string => {
  if (value === 0 || value === '0') return '0';
  if (!value) return '';

  // Convert to string if it's a number
  const strValue = typeof value === 'number' ? String(value) : value;

  // If it has multiple values (e.g., "10px 20px")
  if (/^(\d+(\.\d+)?(px|em|rem|vh|vw|%)\s*){1,4}$/.test(strValue)) {
    return strValue;
  }

  // If it's a single number (no unit), add 'px'
  if (/^\d+(\.\d+)?$/.test(strValue)) {
    return `${strValue}px`;
  }

  // If it's already a single value with unit
  if (
    /^\d+(\.\d+)?(px|em|rem|vh|vw|%|vmin|vmax|cm|mm|in|pt|pc)$/.test(strValue)
  ) {
    return strValue;
  }

  // If it's 'auto', accept this value
  if (strValue === 'auto') {
    return strValue;
  }

  // If it's not a valid format, return empty string
  return '';
};

/**
 * Validates border radius value
 */
export const validateBorderRadius = (value: string | number): string => {
  if (value === 0 || value === '0') return '0';
  if (!value) return '';

  // Convert to string if it's a number
  const strValue = typeof value === 'number' ? String(value) : value;

  // If it has multiple values (e.g., "10px 20px")
  if (/^(\d+(\.\d+)?(px|em|rem|vh|vw|%)\s*){1,4}$/.test(strValue)) {
    return strValue;
  }

  // If it's a single number (no unit), add 'px'
  if (/^\d+(\.\d+)?$/.test(strValue)) {
    return `${strValue}px`;
  }

  // If it's already a single value with unit
  if (
    /^\d+(\.\d+)?(px|em|rem|vh|vw|%|vmin|vmax|cm|mm|in|pt|pc)$/.test(strValue)
  ) {
    return strValue;
  }

  // If it's not a valid format, return empty string
  return '';
};

export const hexToRgb = (hex: string): string => {
  // Remove # if present
  if (!hex) return '';
  hex = hex.replace('#', '');

  // Handle shorthand format (e.g. #ABC)
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `${r}, ${g}, ${b}`;
};
