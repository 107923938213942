import React from 'react';

import { Box, Typography } from '@mui/material';

import { ComponentStyleProps, ImageObject } from '../../pages/types';
import withCommonStyles from '../WithCommoStyles';

interface HeroProps extends ComponentStyleProps {
  title: string;
  subtitle: string;
  backgroundImage: ImageObject;
  sx?: any;
}

const Hero: React.FC<HeroProps> = ({
  title,
  subtitle: paragraph,
  backgroundImage: backgroundImage,
  sx,
}) => {
  if (backgroundImage && backgroundImage.url)
    backgroundImage.url = backgroundImage.url.replace(/ /g, '%20');
  return (
    <Box
      sx={{
        ...sx,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh',
        display: 'flex',
        width: '100vw',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        ...(backgroundImage && {
          backgroundImage: `url(${backgroundImage.url})`,
        }),
      }}
    >
      <Typography
        variant="h1"
        sx={{
          maxWidth: '850px',
          padding: '0px 10px 0px 10px',
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="h5"
        sx={{
          maxWidth: '700px',
          padding: '20px 15px 0px 15px',
        }}
      >
        {paragraph}{' '}
      </Typography>
    </Box>
  );
};

export default withCommonStyles(Hero);
